import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import { Button, Col, Container, Row } from "shards-react";
import MoreInformationModalViewSubmittal from "../components/administration/MoreInformationModalViewSubmittal";
import OriginalIdeaInfo from '../components/administration/OriginalIdeaInfo';
import OriginalIdeaInfoCompleted from '../components/administration/OriginalIdeaInfoCompleted';
import ActivityHistory from '../components/common/ActivityHistory';
import IdeaPercentageBar from '../components/common/IdeaPercentageBar';
import IdeaViewCardNew from '../components/common/IdeaViewCardNew';
import { ReactComponent as PreviousIcon } from "../images/PreviousIcon.svg";
import { ReactComponent as DivisorBarIcon } from "../images/edited_divisor.svg";
import opportunityService from '../services/opportunityService';

function MyIdeaInfo({closeModal, currIdea, updateActivityHistory, type, source, goToSubmittal}) {
  const { t } = useTranslation();
  const [title, setTitle] = useState('');
  const [ideaStage, setIdeaStage] = useState(0);
  const [isLoading, setIsloading] = useState(true);
  const [showIdeaInformation, setShowIdeaInformation] = useState(goToSubmittal);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [opportunity, setOpportunity] = useState(currIdea); 

  useEffect(() => {
    const fetchAndUpdateOpportunity = async (ideaId) => {
      try {
        const data = await opportunityService.fetchOpportunityIdeas(ideaId, 'userDashboard');
        setOpportunity(data);
      } catch (error) {
        setIsloading(false);
        console.error('Error fetching opportunity ideas:', error);
      } finally {
        setIsloading(false);
      }
    };

    if (ideaStage === 0) {
      setTitle(t('Keep your team updated!'));
    }

    if (currIdea && currIdea.id) {
      fetchAndUpdateOpportunity(currIdea.id);
    }
  }, [currIdea]);

  const setFinished = () => {
    setIdeaStage(3);
  }

  const handleGoBack2 = () => {
    if (showIdeaInformation) {
      if (goToSubmittal) {
        closeModal();
      } else {
        setShowIdeaInformation(false);
      }
    } else {
      closeModal();
    }
  }

  const handleGoBack = () => {
    if (showIdeaInformation) {
      setShowIdeaInformation(false);
    } else {
      closeModal();
    }
  }

  const handleRespondNow = () => {
    setIsModalOpen(true);
  }

  const closeModalSubmittal = () => {
    setIsModalOpen(false);
  }

  return (
    <>
    {isLoading ? 
            <div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '30em'}}>
              <ReactLoading type={'spokes'} color={'#633FDA'} width={80} height={80}/>
            </div>
         : (<>
      <Container fluid className="main-content-container px-4" style={{ backgroundColor: 'white' }}>
        <Row>
          <Col md="12" lg="12" className="mt-4 mx-auto">
            <Row>
              <Col md="12" lg="12">
                <h3 className="m-auto" style={{ fontWeight: 600, color: '#303030' }}>{title}</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <DivisorBarIcon />
              </Col>
            </Row>
          </Col>
        </Row>

        {showIdeaInformation && <Row className="mt-4">
          <Col md="12" lg="12">
            <Row>
              <Col md="12" className="m-auto">
                <IdeaViewCardNew canSubmit={false} setFinishedSaving={() => setFinished()} ideaItem={opportunity} ideaStage={0} />
              </Col>
            </Row>
          </Col>
        </Row>}

        {!showIdeaInformation &&
          <div>
            <Row className="mt-4">
              <Col md="12" lg="12">
                <IdeaPercentageBar canClick={false} currIdea={opportunity} view="userDashboard" source={source}></IdeaPercentageBar>
              </Col>
            </Row>

            {opportunity.progress !== 100 && <Row className="mt-4">
              <Col md="12" lg="12">
                <OriginalIdeaInfo canClick={false} currIdea={opportunity} openIdea={() => setShowIdeaInformation(true)} key={Math.random()} id={Math.random()} open={true} shouldReset={false} updateUsers={() => { console.log('') }} user={{}}></OriginalIdeaInfo>
              </Col>
            </Row>}

            {opportunity.progress === 100 && <Row className="mt-4">
              <Col md="12" lg="12">
                <OriginalIdeaInfoCompleted currIdea={opportunity} openIdea={() => setShowIdeaInformation(true)} key={Math.random()} id={Math.random()} open={true} shouldReset={false} updateUsers={() => { console.log('') }} user={{}}></OriginalIdeaInfoCompleted>
              </Col>
            </Row>}

            {source !== 'your-ideas' &&
              <Row className="mt-4 mb-4">
                <Col md="12" lg="12">
                  <ActivityHistory currIdea={opportunity} updateActivityHistory={updateActivityHistory} changeStatus={this.changeBtnStatus} canRefresh={false} closeIdea={() => this.setState({ open: false })} />
                </Col>
              </Row>
            }
          </div>
        }

        <Row>
          <Col lg="12" className="mb-4 mr-auto">
            {type === 'viewIdea' && <PreviousIcon className="functionalButton mr-auto d-block" style={{ minWidth: 140, maxWidth: 140 }} onClick={handleGoBack}></PreviousIcon>}
            {type !== 'viewIdea' && <PreviousIcon className="functionalButton mr-auto d-block" style={{ minWidth: 140, maxWidth: 140 }} onClick={handleGoBack2}></PreviousIcon>}
          </Col>
        </Row>
      </Container>

      {(goToSubmittal && opportunity.status === 'Response Needed') &&
        <>
          <div>
            <Row className="mt-4 floating-buttons2 pb-4">
              <div className="text-center mt-2 pb-2">
                <h5 style={{ fontWeight: 500, color: '#303030' }}>Provide additional information</h5>
                <hr />
              </div>
              <div className="text-center">
                <Button pill outline className="btn-outline-primary-bold" style={{ marginRight: '10px' }} onClick={handleGoBack2}>Respond Later</Button>
                <Button pill onClick={handleRespondNow}>Respond Now</Button>
              </div>
            </Row>
          </div>
          <MoreInformationModalViewSubmittal isOpen={isModalOpen} hiddenSubmittal={true} closeModal={closeModalSubmittal} updateIdea={(idea)=>{
            currIdea = idea;
            setOpportunity(idea);
          }} idea={currIdea} type="submitInfo" />
        </>
      }
      </>)}
    </>
  )
}

MyIdeaInfo.propTypes = {
  closeModal: PropTypes.func,
  currIdea: PropTypes.object,
  updateCurrIdea: PropTypes.func,
  updateActivityHistory: PropTypes.func,
  type: PropTypes.string,
  source: PropTypes.string,
  goToSubmittal: PropTypes.bool
};

MyIdeaInfo.defaultProps = {
  signedIn: true,
};

export default withTranslation()(MyIdeaInfo);
